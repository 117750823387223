















































import { Component, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/account/types';
import FormField from '@/utilities/validation/formfield';
import ValidatedForm from '@/utilities/validation/validatedform';
import emailValidator from '@/utilities/validators/email';
import { SubscriptionUsers } from '@/interfaces/subscriptionUsers';
import UserInfo from '@/interfaces/userInfo';
import { AxiosResponse } from 'axios';

const namespace: string = 'account';

@Component({ components: { VButton } })
export default class VAddUsers extends Vue {
  @Action('subscriptionUsers', { namespace }) private getOrganizationInfo!: types.SubscriptionUsersAction;
  @Action('addSubscriptionUser', { namespace }) private addOrganizationUser!: types.AddSubscriptionUserAction;
  @Action('deleteSubscriptionUser', { namespace }) private deleteOrganizationUser!: types.DeleteSubscriptionUserAction;
  @Getter('userInfo', { namespace }) private userInfo!: UserInfo;

  private addingSubscriptionUser = false;
  private deletingOrganizationUser = false;
  private subscriptionUsers: SubscriptionUsers = new SubscriptionUsers();

  private showServerError = false;
  private serverError = '';


  private newUserForm: ValidatedForm = new ValidatedForm({
    email: new FormField(emailValidator, ''),
  });

  get newUserFormValid() { return this.newUserForm.fieldValidity(); }

  private created(): void {

    this.getOrganizationInfo().then((response) => {
      this.subscriptionUsers = response;
    });
  }

  private submitAddSubscriptionUser() {
    this.showServerError = false;
    if (this.newUserForm.formValid()) {
      this.addingSubscriptionUser = true;
      this.addOrganizationUser({
        email: this.newUserForm.fields.email.value,
        username: this.userInfo.email,
      }).then(() => {
        this.subscriptionUsers.users.push(this.newUserForm.fields.email.value);
      }).catch((response: AxiosResponse) => {
        this.serverError = response.data[0];
        this.showServerError = true;
      }).finally(() => {
        this.addingSubscriptionUser = false;
      });
    }
  }

  private submitDeleteOrganizationUser(email: string) {
    this.deletingOrganizationUser = true;
    this.deleteOrganizationUser({ email }).then(() => {
      this.subscriptionUsers.users = this.subscriptionUsers.users.filter((user) => user !== email);
    }).finally(() => {
      this.deletingOrganizationUser = false;
    });
  }

  get filteredOrganizationUsers() {
    return this.subscriptionUsers.users.filter((user) => user !== this.userInfo.email);
  }

}
