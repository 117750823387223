export interface ISubscriptionUsers {
  created: string;
  id: number;
  isActive: boolean;
  modified: string;
  name: string;
  slug: string;
  users: string[];
}

export class SubscriptionUsers implements ISubscriptionUsers {
  public created = '';
  public id = -1;
  public isActive = false;
  public modified = '';
  public name = '';
  public slug = '';
  public users: string[] = [];
}
