












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { setTimeout } from 'timers';
import VButton from '@/components/VButton.vue';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/account/types';
import { toBase64, dataURItoFile } from '@/utilities/image';
import { AxiosResponse } from 'axios';

const namespace: string = 'account';

@Component({ components: { VButton }})
export default class ProfileImage extends Vue {


  @Action('profileImage', { namespace }) private getProfileImage!: types.ProfileImageAction;
  @Action('uploadImage', { namespace }) private uploadImage!: types.UploadImageAction;

  private loadingImage = false;
  private imgFile = '';
  private errorCode: number = -1;

  private created() {
    this.loadProfileImage();
  }

  private loadProfileImage(refresh: boolean = false) {
    this.loadingImage = true;
    this.getProfileImage(refresh).then((response) => {
      (this.$refs.profile as any).src = response;
    }).finally(() => {
      this.loadingImage = false;
    });
  }

  private setFile(file: any) {
    toBase64(file).then((base64) => {
      this.imgFile = base64 as string;
      (this.$refs['image-modal-1'] as any).show();
    });
  }

  private submitFile() {
    const base64 = (this.$refs.cropper as any).getCroppedCanvas().toDataURL();
    const formData = new FormData();
    formData.append('file1', dataURItoFile(base64));
    this.loadingImage = true;
    this.uploadImage(formData)
    .catch((response: AxiosResponse) => {
      this.errorCode = response.status;
      (this.$refs['image-modal-2'] as any).show();
    })
    .finally(() => {
      (this.$refs['image-modal-1'] as any).hide();
      this.loadingImage = false;
      this.loadProfileImage(true);
    });
  }
}
