






































































import { Component, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import ProfileImage from '@/components/ProfileImage.vue';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/account/types';
import FormField from '@/utilities/validation/formfield';
import ValidatedForm from '@/utilities/validation/validatedform';
import UserInfo from '@/interfaces/userInfo';

const namespace: string = 'account';

@Component({
  components: { ProfileImage, VButton },
})
export default class VSettings extends Vue {
  @Action('userInfo', { namespace }) private getUserInfo!: types.UserInfoAction;
  @Action('updateUserInfo', { namespace }) private updateUserInfo!: types.UpdateUserInfoAction;
  @Getter('userInfo', { namespace }) private userInfo!: UserInfo;

  private updatingUserInfo = false;
  private editSettings = false;

  private userInfoForm: ValidatedForm = new ValidatedForm({
    firstName: new FormField((value, dirty) => value.length < 30, ''),
    lastName: new FormField((value, dirty) => value.length < 30, ''),
  });
  get userInfoFormValid() { return this.userInfoForm.fieldValidity(); }

  private created() {
    this.userInfoForm.fields.firstName.value = this.userInfo.firstName;
    this.userInfoForm.fields.lastName.value = this.userInfo.lastName;
    this.getUserInfo();
  }

  private submitUserInfo() {
    if (this.userInfoForm.formValid()) {
      this.updatingUserInfo = true;
      this.updateUserInfo({
        firstName: this.userInfoForm.fields.firstName.value,
        lastName: this.userInfoForm.fields.lastName.value,
      }).finally(() => {
        this.updatingUserInfo = false;
        this.editSettings = false;
      });
    }
  }

}
