












































































































import { Component, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import ProfileImage from '@/components/ProfileImage.vue';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/account/types';
import FormField from '@/utilities/validation/formfield';
import ValidatedForm from '@/utilities/validation/validatedform';
import SubscriptionInfo from '@/interfaces/subscriptionInfo';
import emailValidator from '@/utilities/validators/email';
import { AxiosError } from 'axios';

const namespace: string = 'account';

@Component({
  components: { ProfileImage, VButton },
})
export default class VSubscription extends Vue {

  @Action('subscriptionInfo', { namespace }) private getSubscriptionInfo!: types.SubscriptionInfoAction;
  @Action('updateSubscriptionInfo', { namespace }) private updateSubscriptionInfo!: types.UpdateSubscriptionInfoAction;
  @Getter('subscriptionInfo', { namespace }) private subscriptionInfo!: SubscriptionInfo;

  private updatingSubscriptionInfo = false;
  private editSubscription = false;
  private serverIbanError = false;

  private subscriptionForm: ValidatedForm = new ValidatedForm({
    companyName: new FormField((value: any, dirty: boolean) => value !== '', ''),
    kvkNr: new FormField((value, dirty) => !isNaN(Number(value)) && value.length === 8, ''),
    ibanName: new FormField((value, dirty) => value !== '', ''),
    ibanNr: new FormField((value, dirty) =>
      value.match(/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/) !== null
    , ''),
    type: new FormField((value, dirty) => value !== '', ''),
    incassoAccepted: new FormField((value, dirty) => value === 'true', ''),
    inlenerWebModule: new FormField((value, dirty) => null, ''),
  });

  get subscriptionFormValid() { return this.subscriptionForm.fieldValidity(); }

  private created() {
    this.getSubscriptionInfo().then((response) => {
      const sfields = this.subscriptionForm.fields;
      sfields.companyName.value = response.companyName;
      sfields.kvkNr.value = response.kvkNr !== null ? response.kvkNr.toString() : '';
      sfields.ibanName.value = response.ibanName !== null ? response.ibanName : '';
      sfields.ibanNr.value = response.ibanNr !== null ? response.ibanNr.toString() : '';
      sfields.type.value = response.type;
      sfields.inlenerWebModule.value = response.inlenerWebModule.toString();
      sfields.incassoAccepted.value = response.incassoAccepted !== null ? response.incassoAccepted.toString() : 'false';
    });
  }

  private submitSubscriptionInfo() {
    if (this.subscriptionForm.formValid()) {
      this.updatingSubscriptionInfo = true;
      this.updateSubscriptionInfo({
        companyName: this.subscriptionForm.fields.companyName.value,
        ibanName: this.subscriptionForm.fields.ibanName.value,
        ibanNr: this.subscriptionForm.fields.ibanNr.value,
        incassoAccepted: this.subscriptionForm.fields.incassoAccepted.value === 'true',
        kvkNr: Number(this.subscriptionForm.fields.kvkNr.value),
        type: this.subscriptionForm.fields.type.value,
        inlenerWebModule: this.subscriptionForm.fields.inlenerWebModule.value === 'true',
      })
      .then(() => {
        this.editSubscription = false;
        this.serverIbanError = false;
      })
      .catch((error: AxiosError) => {
        this.serverIbanError = true;
        this.subscriptionForm.fields.ibanNr.validate();
      })
      .finally(() => {
        this.updatingSubscriptionInfo = false;
      });
    }
  }
}

